class CategoryExtraLanguage {
  id;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.language = data.language;
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      name : item.name,
      description : item.description,
      language : item.language,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new CategoryExtraLanguage(data)
  }
}
export {CategoryExtraLanguage, DataConverter} 
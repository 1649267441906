<template>
  <div>
    <portal to="title-page">
      <h1>Editar categoria</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPencil}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-auto"><v-icon>{{mdiPlus}}</v-icon> lenguaje</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- <v-text-field
                label="Idioma"
                :prepend-icon="mdiFlag"
                :value="language ? language.name : ''"
                dense
                readonly
              ></v-text-field> -->
              <v-select
                v-if="registerLanguages != null"
                v-model="language"
                :items="registerLanguages"
                item-text="language"
                return-object
                :prepend-icon="mdiFlag"
                :rules="[v => !!v || 'El idioma es requerido']"
                label="Seleccione el idioma"
                required
                @change="changeLang"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="categoryExtraLanguage.name"
              :rules="nameRules"
              :prepend-icon="mdiText"
              :disabled="ref && ref.id === '35GgLDUefBqgKGSqiMhv'"
              required
              dense
              label="Nombre*" hint="Ingrese el nombre de la categoria" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p>Descripcion</p>
              <vue-editor
                id="description"
                :class="categoryExtraLanguage.description != null && categoryExtraLanguage.description == '' ? 'error' : ''"
                v-model="categoryExtraLanguage.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              :prepend-icon="mdiFlag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template> 
<script>
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose, mdiPencil } from '@mdi/js';
  import { VueEditor } from "vue2-editor";
  import Language from "@/models/Language.js";
  import CategoryExtra from '@/models/CategoryExtra';
  import {DataConverter} from "@/FirestoreDataConverters/CategoryExtraLanguage.js";
  export default {
    name: "CategoryExtraEdit",
    components: { VueEditor },
    data() {
      return {
        languageModel:new Language,
        categoriesModel : new CategoryExtra,
        ///////////////////////////////////////////////////////
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        mdiPencil:mdiPencil,
        ///////////////////////////////////////////////////////
        systemLanguages:null,
        registerLanguages:null,
        availableLanguages:null,
        ///////////////////////////////////////////////////////
        validForm:true,
        language:null,
        newLang:null,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
        nameRules: [
          v => !!v || 'El nombre es obligatorio',
          //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        categoryExtraLanguage:{name:null,description:null},
        ref:null,
        descriptionRules: [
          v => !!v || 'La descripcion es obligatoria',
          v => (v && v.length >= 10) || 'La descripcion debe ser de al menos 10 caracteres',
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        unsubscribeLangs:null,
        selectedLanguageId:'es',
        dialogAddLang:{
          show:false
        },
        validFormLang:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          if (this.categoryExtraLanguage.id == 'es') {
            this.ref.update({name:this.categoryExtraLanguage.name}).then(() => {
              this.ref.collection('categoryExtraLanguages').doc(this.categoryExtraLanguage.id).update({
                name:this.categoryExtraLanguage.name,
                description:this.categoryExtraLanguage.description,
                language:this.categoryExtraLanguage.language
              })
              .then(() => {
                loading.hide();
                this.showSnackbarSuccess('La categoria se actualizo correctamente');
              })
              .catch(error => {
                loading.hide();
                console.log(error);
                this.showSnackbarError('Error al actualizar el lenguaje de la categoria');
              })
            })
            .catch( error=> {
              console.log(error);
              loading.hide();
              this.showSnackbarError('Error al actualizar la categoria');
            })
          } else {
            this.ref.collection('categoryExtraLanguages').doc(this.categoryExtraLanguage.id).update({
              name:this.categoryExtraLanguage.name,
              description:this.categoryExtraLanguage.description,
              language:this.categoryExtraLanguage.language
            })
            .then(() => {
              loading.hide();
              this.showSnackbarSuccess('La categoria se actualizo correctamente');
            })
            .catch(error => {
              loading.hide();
              console.log(error);
              this.showSnackbarError('Error al actualizar el lenguaje de la categoria');
            })
          }
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      setAvailableLanguages() {
        this.availableLanguages = [];
        if (this.systemLanguages !== null) {
          if (this.registerLanguages !== null) {
            for(var i in this.systemLanguages) {
              let langId = this.systemLanguages[i].id;
              let exist = this.registerLanguages.find(item => item.id == langId);
              if (!exist) {
                this.availableLanguages.push(this.systemLanguages[i]);
              }
            }
          } else {
            this.availableLanguages = this.systemLanguages;
          }
        }
      },
      getLangs() {
        this.unsubscribeLangs = this.ref.collection('categoryExtraLanguages').withConverter(DataConverter).onSnapshot(docs => {
          this.registerLanguages = []
          var index, i = 0;
          docs.forEach(doc => {
            this.registerLanguages.push(doc.data());
            if (doc.data().id == this.selectedLanguageId) {
              index = i;
            }
            i++;
          })
          this.language = this.registerLanguages[index];
          this.categoryExtraLanguage = this.registerLanguages[index];
          this.setAvailableLanguages();
        })
      },
      changeLang() {
        this.selectedLanguageId = this.language.id;
        this.categoryExtraLanguage = this.language;
      },
      addLang() {
        if (this.$refs.formlang.validate()) {
          let loading = this.$loading.show();
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.ref.collection('categoryExtraLanguages').doc(this.newLang.id).set({
            description:'',
            language:this.newLang.name,
            name:''
          }).then(() => {
            this.getLangs();
            this.selectedLanguageId = this.newLang.id;
            loading.hide();
            this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
            this.dialogAddLang.show = false;
          });
        }
      },
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.systemLanguages = [];
        snap.forEach( doc => {
          this.systemLanguages.push(doc.data());
        })
        this.setAvailableLanguages();
      })
      this.categoriesModel.findById(this.$route.params.id).onSnapshot(snapDoc => {
        if (snapDoc.exists) {
          this.ref = snapDoc.ref;
          this.categoryExtraLanguage = {
            name:snapDoc.data().name,
            description:snapDoc.data().description,
          }
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.getLangs();
        }
      })
    },
  }
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>